<!-- Auto Login Loader -->
<div *ngIf="(loginInProcessService.loginInProcessScreen$ | async ) " class="loading-background">
  <div>
    <div class="logo" >
      <img class="dark_logo" src="../../../../assets/icons/Rewaa-dark-logo.svg" />
      <img class="dark_logo_animated" src="../../../../assets/icons/Rewaa-dark-logo.svg" />
      <img class="full_logo" src="../../../../assets/icons/Rewaa-full-logo.svg" />
    </div>

    <div class="text-container">
      <div class="text" id="text1">{{'autoLogin.welcome' | translate}}</div>
      <div class="text" id="text2">{{'autoLogin.settingUp' | translate}}</div>
      <div class="text" id="text3">{{'autoLogin.technicalPartner' | translate}}</div>
    </div>
  </div>
</div>

<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>

<div class="auth-page min-h-screen align-items-center justify-content-center md:flex w-full flex-row-reverse"  [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  <rw-side-panel></rw-side-panel>

  <div class="auth-form-area flex align-items-center bg-white justify-content-center w-full mx-0 md:mx-3">
    <div class="py-5 px-3">
      <div class="flex align-items-center justify-content-between gap-2 md:gap-5 pb-5">
        <img class="max-w-full h-auto d-block auth-logo" src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="">
        <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      <form
        [formGroup]="fGroup"
        #ngForm
        class="p-fluid grid auth-form"
      >
        <div class="field col-12 rw__pb-0">
          <label class="rw__fs-6 fw-500 rw__mb-3">{{'auth.verifyYourAccountTitle' | translate}}</label>
          <p class="rw__fs-9 text-grey-60 rw__mb-3" [innerHTML]="message | translate : {medium: medium}"></p>
          <p class="rw__fs-9 text-grey-60 rw__mb-3" [innerHTML]="translationPrefix+'.enterCodeToVerify' | translate"><strong>{{ medium }}</strong></p>
        </div>
        <div *ngIf="shouldShowRetryLimitReachedMessage()" class="field col-12">
          <p-messages [value]="limitReachedMessage">test</p-messages>
        </div>
        <div class="field col-12 rw__mb-5">
          <div class="rw__otp rw__otp-w-full">
            <rw-otp-input [formControl]="otpFormControl" [otpLength]="6" (getOTP)="getOTP($event)" [autoFocusInput]="true"></rw-otp-input>
          </div>
            <rw-input-error *ngIf="errMessage"
            [text]="errMessage | translate" class="block rw__mt-2"></rw-input-error>
        </div>
        <div class="field col-12 rw__my-2 d-flex align-items-center justify-content-between w-full">
          <button pButton type="button" class="rw__button p-button-text w-auto rw__px-0 bg-transparent" label="{{'auth.resendCodeTitle' | translate}}"
            (click)="resendConfirmationV2(email.value, phone)" pendo-tracker="crb" [disabled]="isRetryDisabled()"></button>
          <span *ngIf="timerVisible" class="rw__fs-9 text-grey-60 block" [innerHTML]="'resetTimer' | translate : {countDownSeconds: countdownSeconds}" ></span>
        </div>
        <div class="field col-12 rw__my-2 d-flex align-items-center justify-content-between w-full">
          <button pButton type="button" class="rw__button p-button-text w-auto rw__px-0 bg-transparent" [disabled]="isRetryDisabledForEmail()"
            label="{{'auth.changeMediumToEmail' | translate}}" *ngIf="deliveryMedium === deliveryMediumEnum.SMS"
            (click)="toggleDeliveryMedium()"></button>
          <button pButton type="button" class="rw__button p-button-text w-auto rw__px-0 bg-transparent" [disabled]="isRetryDisabledForSms()"
            label="{{'auth.changeMediumTomPhone' | translate}}" *ngIf="deliveryMedium === deliveryMediumEnum.EMAIL"
            (click)="toggleDeliveryMedium()"></button>
        </div>
        <div class="field col-12 rw__mb-0 text-center">
          <button pendo-tracker="crb-1" pButton pRipple
            type="submit"
            class="login-button rw__button rw__mb-3"
            label="{{'auth.verifyYourAccountTitle' | translate}}"
            (click)="confirm(fGroup.value)"
            [disabled]="isShowSpinner || !vButtonVisibility"
          >
          </button>
          <button pButton pRipple
            type="button"
            class="p-button-text bg-white rw__button rw__button-hide-default-label w-full d-flex justify-content-center"
            (click)="navigateToRegister()"
          ><i class="rw__me-4" [ngClass]="lang === 'ar' ? 'fa-regular fa-arrow-right' : 'fa-regular fa-arrow-left'"></i>{{ 'auth.goBack' | translate }}
          </button>
          <hr class="rw__mb-4" />
          <span class="rw__my-3">
            {{ 'Need Help?' | translate}}
            <button  class="rw__button text-primary border-0 bg-transparent p-button-text" (click)="startIntercom()">{{
              'Contact Support' | translate
            }}</button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
