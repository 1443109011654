import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  interval,
  takeUntil,
  takeWhile,
  Subscription,
} from 'rxjs';
import { LocalStorageKey } from '../../shared/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

// type LocalStorageResendTimer = {
//   currentResendTimer: number;
//   timestamp: number;
// };

@Injectable()
export class TimerService {
  private timerActive$ = new BehaviorSubject<boolean>(false);

  private countdownSeconds$ = new BehaviorSubject<number>(0);

  private countdownInterval$ = interval(1000);

  private destroy$ = new Subject<void>();

  private countdownSubscription: Subscription;

  constructor(private localStorageService: LocalStorageService) {
    this.checkForTimer();
  }

  get currentResendTimer(): number {
    return this.localStorageService.getItem<number>(
      LocalStorageKey.resendTimer,
    );
  }

  public checkForTimer(): void {
    const timerActiveSince = this.currentResendTimer;

    const timerStartSeconds = 25;

    const timeLeft =
      timerStartSeconds - (Math.floor(Date.now() / 1000) - timerActiveSince);

    if (timeLeft < 0) {
      this.localStorageService.removeItem(LocalStorageKey.resendTimer);
    } else if (timerActiveSince) {
      this.timerActive$.next(true);
      this.resumeTimer(timerStartSeconds);
    }
  }

  public startTimer(seconds: number): void {
    // this.setStartTimer(seconds);
    this.setStartTimeOnLocalStorage();
    this.resumeTimer(seconds);
  }

  public startTimerV2(startTimeInSeconds: number): void {
    const timerActiveSince = this.currentResendTimer;

    if (timerActiveSince) {
      this.timerActive$.next(true);
      return;
    }

    this.localStorageService.setItem<number>(
      LocalStorageKey.resendTimer,
      Math.floor(Date.now() / 1000),
    );

    this.timerActive$.next(true);
    this.resumeTimer(startTimeInSeconds);
    // this.incrementTimerOnResend();
  }

  private resumeTimer(seconds: number): void {
    this.countdownSeconds$.next(seconds);
    this.countdownSubscription = this.countdownInterval$
      .pipe(
        takeWhile(() => this.countdownSeconds$.value > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.countdownSeconds$.next(
          seconds - (Math.floor(Date.now() / 1000) - this.currentResendTimer),
        );
        if (this.countdownSeconds$.value <= 0) {
          this.stopCountdown();
        }
      });
  }

  setStartTimeOnLocalStorage(): void {
    if (!this.currentResendTimer) {
      this.localStorageService.setItem<number>(
        LocalStorageKey.resendTimer,
        Math.floor(Date.now() / 1000),
      );
      this.timerActive$.next(true);

      setTimeout(async () => {
        this.localStorageService.removeItem(LocalStorageKey.resendTimer);
        this.timerActive$.next(false);
      }, 25000);
    }
  }

  stopCountdown(): void {
    this.timerActive$.next(false);
    this.localStorageService.removeItem(LocalStorageKey.resendTimer);

    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  isTimerActive(): Observable<boolean> {
    return this.timerActive$.asObservable();
  }

  currentTimeRemaining(): Observable<number> {
    return this.countdownSeconds$.asObservable();
  }

  // private getTimersFromLocalStorage(): LocalStorageResendTimer {
  //   return (
  //     this.localStorageService.getItem<LocalStorageResendTimer>(
  //       LocalStorageKey.StartingResendTime,
  //     ) || { currentResendTimer: 30, timestamp: Date.now() }
  //   );
  // }

  // private getTimer(): number {
  //   const timers = this.getTimersFromLocalStorage();

  //   if (this.isTimerExpired(timers.timestamp)) {
  //     this.resetTimers();
  //     return 30;
  //   }

  //   return timers.currentResendTimer;
  // }

  // private isTimerExpired(timestamp: number): boolean {
  //   const oneHourInMs = 60 * 60 * 1000;
  //   return Date.now() - timestamp > oneHourInMs;
  // }

  // private setStartTimer(timerValue: number): void {
  //   const timers = this.getTimersFromLocalStorage();

  //   timers.currentResendTimer = timerValue;

  //   this.localStorageService.setItem(
  //     LocalStorageKey.StartingResendTime,
  //     timers,
  //   );
  // }

  // public resetTimers(): void {
  //   this.localStorageService.removeItem(LocalStorageKey.StartingResendTime);
  //   this.stopCountdown();
  // }

  // private incrementTimerOnResend(): void {
  //   const currentTimer = this.getTimer();
  //   const newTimer = currentTimer >= 120 ? 120 : currentTimer + 15;
  //   this.setStartTimer(newTimer);
  // }
}
