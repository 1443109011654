import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap, timer, Subscription } from 'rxjs';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { DomSanitizer } from '@angular/platform-browser';
import { Intercom } from 'ng-intercom';
import { Message } from 'primeng/api';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { LoginDetails, SignupConfirmation } from '../../auth.models';
import { AuthService } from '../../services/auth.service';
import { ConfirmationCodeValidator } from './validators/confirmationCodeValidator';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import {
  MerchantService,
  ResendConfirmationCodeResponse,
} from '../../services/merchant.service';
import { TimerService } from '../../services/timer.service';
import { DeliveryMediumType, UserType } from '../../../shared/types/auth.types';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { LoginInProcessService } from '../../services/login-in-process.service';

@Component({
  selector: 'rw-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss'],
})
@AutoUnsubscribe
export class ConfirmRegistrationComponent
  implements OnInit, AutoUnsubscribeI, OnDestroy
{
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  isShowSpinner = false;

  errMessage: string;

  merchantSignUpInterval$ = timer(2500, 1500);

  count = 0;

  subscription: Subscription = undefined;

  state$: Observable<Object>;

  changeLang: string;

  lang: string;

  timerVisible = false;

  unverifiedUser = false;

  vButtonVisibility = false;

  registerData: any;

  previousRegisterData: any;

  loginInProcess = false;

  message = '';

  loggedIn = false;

  countdownSeconds = 0;

  confirmationOTP = '';

  translationPrefix = 'confirmRegistration';

  medium: string;

  POLLING_LIMIT = 15;

  enterYourCodeMessage: string;

  otpFormControl: FormControl<number | null>;

  deliveryMedium: DeliveryMediumType;

  deliveryMediumEnum = DeliveryMediumType;

  retryLimitReachedForSms = false;

  retryLimitReachedForEmail = false;

  isMediumChanged = false;

  registrationQueryParams: {
    email: string;
    type: string;
    cc: string;
    phone: string;
    resend: boolean;
  };

  startTimerValue = 30;

  limitReachedMessage: Message[] = [
    {
      severity: 'info',
      summary: '',
      closable: false,
      detail:
        'Resend limit exceeded. Please try again after 1 hour or contact our support team',
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    public loginInProcessService: LoginInProcessService,
    private userAuthenticationService: UserAuthenticationService,
    private intercom: Intercom,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private toaster: CustomToastService,
    public translate: TranslateService,
    private timerService: TimerService,
    private localStorageService: LocalStorageService,
  ) {
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.use(this.lang);
    if (this.lang === 'en') {
      this.changeLang = 'ar';
    } else {
      this.changeLang = 'en';
    }
    this.registerData = this.router.getCurrentNavigation()?.extras?.state;
    this.previousRegisterData = this.getRegisterDataFromLocalStorage();
    if (this.registerData) {
      this.storeRegisterDataInLocalStorage(this.registerData);
    }
    this.otpFormControl = new FormControl(
      null,
      Validators.compose([Validators.required]),
    );
  }

  get email() {
    return this.fGroup.get('email');
  }

  get code() {
    return this.fGroup.get('code');
  }

  get phone(): string {
    return this.registrationQueryParams.phone;
  }

  startIntercom(): void {
    this.intercom.show();
  }

  private getMessageToShowAndSetMediumType(
    email: string,
    countryCode: string,
    phone: string,
    type: string,
  ) {
    if (type === UserType.Gcc) {
      this.message = `${this.translationPrefix}.phoneMsg`;
      this.medium = `${countryCode}${phone}`;
      this.deliveryMedium = DeliveryMediumType.SMS;
    } else if (type === UserType.GccWithoutPh) {
      this.message = `${this.translationPrefix}.phoneMsg2`;
      this.medium = phone;
      this.deliveryMedium = DeliveryMediumType.SMS;
    } else {
      this.message = `${this.translationPrefix}.emailMsg`;
      this.medium = email;
      this.deliveryMedium = DeliveryMediumType.EMAIL;
    }
  }

  switchLang(lang: string): void {
    this.authService.switchLanguage(lang);
  }

  ngOnDestroy(): void {
    this.loginInProcessService.stopLoginInProcessScreenLoading();
  }

  ngOnInit(): void {
    this.createForm();
    let email;
    let countryCode;
    let phone;
    let type;
    let resend: boolean;
    this.activatedRoute.queryParamMap.subscribe((payload) => {
      email = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('email'),
      );
      type = this.sanitizer.sanitize(SecurityContext.HTML, payload.get('type'));
      countryCode = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('cc'),
      );
      phone = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('phone'),
      );
      resend = !!this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('resend'),
      );

      const url = new URL(window.location.href);
      url.searchParams.delete('resend');
      window.history.replaceState({}, '', url.toString());
      this.fGroup.controls.email.reset({ value: email, disabled: false });
    });

    this.registrationQueryParams = {
      email,
      type,
      cc: countryCode,
      phone,
      resend,
    };

    this.getMessageToShowAndSetMediumType(email, countryCode, phone, type);

    const isDataModified =
      JSON.stringify(this.registerData) !==
      JSON.stringify(this.previousRegisterData);

    if (isDataModified) {
      this.timerService.stopCountdown();
    }

    if (resend && !this.timerService.currentResendTimer) {
      this.resendConfirmationV2(email, phone);
    } else {
      this.timerService.startTimerV2(this.startTimerValue);
    }

    this.timerService
      .isTimerActive()
      .pipe(
        tap((timerEnded) => {
          this.timerVisible = timerEnded;
        }),
      )
      .subscribe();

    this.timerService
      .currentTimeRemaining()
      .pipe(
        tap((timeRemaining) => {
          this.countdownSeconds = timeRemaining;
        }),
      )
      .subscribe();
  }

  createForm(): void {
    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      code: [
        '',
        [Validators.required, ConfirmationCodeValidator.cannotContainSpace],
      ],
    });
  }

  getOTP(event: string): void {
    this.confirmationOTP = event;
    this.errMessage = null;
    if (this.confirmationOTP.length === 6) {
      this.vButtonVisibility = true;
    } else {
      this.vButtonVisibility = false;
    }
  }

  isRetryDisabled(): boolean {
    return (
      this.timerVisible ||
      (this.deliveryMedium === DeliveryMediumType.EMAIL &&
        this.retryLimitReachedForEmail) ||
      (this.deliveryMedium === DeliveryMediumType.SMS &&
        this.retryLimitReachedForSms)
    );
  }

  shouldShowRetryLimitReachedMessage(): boolean {
    return (
      !this.timerVisible &&
      this.retryLimitReachedForEmail &&
      this.retryLimitReachedForSms
    );
  }

  isRetryDisabledForEmail(): boolean {
    return this.timerVisible || this.retryLimitReachedForEmail;
  }

  isRetryDisabledForSms(): boolean {
    return this.timerVisible || this.retryLimitReachedForSms;
  }

  checkMerchantSignUpStatus(email: string): void {
    this.subscriptionRefs = this.merchantService
      .getMerchantSignUpStatus(email)
      .subscribe(async (isSigningUp) => {
        if (!isSigningUp) {
          this.subscription.unsubscribe();
          if (this.loggedIn) return;
          this.loggedIn = true;
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          const { password } = this.registerData;
          await this.userAuthenticationService.login(
            new LoginDetails(email, password),
          );
          this.router.navigateByUrl('').finally(() => {
            this.loginInProcessService.stopLoginInProcessScreenLoading();
          });
        } else if (this.count > this.POLLING_LIMIT) {
          this.subscription.unsubscribe();
          this.router.navigate(['/login']);
        } else {
          this.count += 1;
        }
      });
  }

  async confirm(confirmation: SignupConfirmation): Promise<void> {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .confirmSignup(confirmation.email, this.confirmationOTP)
      .subscribe({
        next: async () => {
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          this.loginInProcessService.showLoginInProcessScreen();
          this.subscription = this.merchantSignUpInterval$.subscribe(() => {
            this.checkMerchantSignUpStatus(confirmation.email);
          });
        },
        error: (err) => {
          if (err.code === 'UserNotFoundException') {
            this.errMessage = 'Email or Confirmation Code is not correct.';
          } else {
            this.errMessage =
              'Confirmation Code didn’t match the code you provided';
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  setStartTimerBasedOnRetryCount(
    payload: ResendConfirmationCodeResponse,
  ): void {
    const startTime = 30;
    const increment = 15;

    const totalResendCount =
      payload.resendCount[DeliveryMediumType.EMAIL] +
      payload.resendCount[DeliveryMediumType.SMS] -
      1;

    this.startTimerValue = Math.min(
      startTime + increment * totalResendCount,
      120,
    );
  }

  checkRetryLimit(payload: ResendConfirmationCodeResponse): void {
    if (payload.resendCount[DeliveryMediumType.SMS] === 4) {
      this.retryLimitReachedForSms = true;
    }

    if (payload.resendCount[DeliveryMediumType.EMAIL] === 4) {
      this.retryLimitReachedForEmail = true;
    }
  }

  handleResendConfirmationSuccess(): void {
    this.isMediumChanged = false;
    this.timerService.startTimerV2(this.startTimerValue);
    this.errMessage = null;
    this.toaster.success(
      this.translate.instant('code has been re-sent successfully'),
    );
  }

  private setRetryLimitReached(): void {
    if (this.deliveryMedium === DeliveryMediumType.EMAIL) {
      this.retryLimitReachedForEmail = true;
    } else {
      this.retryLimitReachedForSms = true;
    }
  }

  handleResendConfirmationErrorForLimitExceeded(
    payload?: ResendConfirmationCodeResponse,
  ): void {
    this.toaster.error(
      this.translate.instant(`${this.translationPrefix}.limitExceededMessage`),
    );

    if (!payload) {
      this.setRetryLimitReached();
    }
  }

  resendConfirmationV2(email: string, phone: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .resendConfirmationCodeV2(
        email,
        phone,
        this.deliveryMedium,
        this.isMediumChanged,
      )
      .subscribe({
        next: (value) => {
          this.setStartTimerBasedOnRetryCount(value);
          this.checkRetryLimit(value);
          if (value.success) {
            this.handleResendConfirmationSuccess();
          } else {
            this.handleResendConfirmationErrorForLimitExceeded(value);
          }
        },
        error: (err) => {
          if (err.error?.message === 'LimitExceededException') {
            this.handleResendConfirmationErrorForLimitExceeded();
          } else {
            this.errMessage = err.message;
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  private storeRegisterDataInLocalStorage(data: object): void {
    const twoMinutesInMilliseconds = 120000;
    const expiryTime = new Date().getTime() + twoMinutesInMilliseconds;

    this.localStorageService.setItem(LocalStorageKey.PreviousRegisterData, {
      ...data,
      expiryTime,
    });
  }

  private getRegisterDataFromLocalStorage(): object {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registerData = this.localStorageService.getItem<any>(
      LocalStorageKey.PreviousRegisterData,
    );

    if (!registerData) {
      return null;
    }

    if (new Date().getTime() > registerData.expiryTime) {
      this.localStorageService.removeItem(LocalStorageKey.PreviousRegisterData);
      return null;
    }

    delete registerData.expiryTime;

    return registerData;
  }

  public toggleDeliveryMedium(): void {
    this.deliveryMedium =
      this.deliveryMedium === DeliveryMediumType.EMAIL
        ? DeliveryMediumType.SMS
        : DeliveryMediumType.EMAIL;

    const { email, cc, phone, type } = this.registrationQueryParams;

    if (this.deliveryMedium === DeliveryMediumType.EMAIL) {
      this.message = `${this.translationPrefix}.emailMsg`;
      this.medium = email;
    } else if (type === UserType.Gcc) {
      this.message = `${this.translationPrefix}.phoneMsg`;
      this.medium = `${cc}${phone}`;
    } else {
      this.message = `${this.translationPrefix}.phoneMsg2`;
      this.medium = phone;
    }

    this.isMediumChanged = true;
    this.resendConfirmationV2(this.email.value, phone);
  }

  /** @deprecated */
  resendConfirmation(email: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .resendConfirmationCode(email)
      .subscribe({
        next: () => {
          this.timerService.startTimer(25);
          this.errMessage = null;
          this.toaster.success(
            this.translate.instant('code has been re-sent successfully'),
          );
        },
        error: (err) => {
          if (
            err.error?.name === 'LimitExceededException' ||
            err.error?.message === 'LimitExceededException'
          ) {
            this.toaster.error(
              this.translate.instant(
                `${this.translationPrefix}.limitExceededMessage`,
              ),
            );
          } else {
            this.errMessage = err.message;
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  navigateToRegister() {
    this.router.navigate(['/register'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        registered: true,
      },
      state: { ...this.registerData },
    });
  }
}
