import { Component, ElementRef, OnDestroy, OnInit, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { CustomToastService } from 'src/app/shared/services/custom-toast.service';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { LoginDetails, ReferralLink } from '../../auth.models';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { AuthService } from '../../services/auth.service';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { removeLanguageFromUrlIfRequired } from '../../../utils/localizedUrl';
import { DeliveryMediumType, UserType } from '../../../shared/types/auth.types';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { PermissionServiceV2 } from '../../services/permission-v2.service';

@Component({
  selector: 'rw-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@AutoUnsubscribe
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit, AutoUnsubscribeI {
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  errMessage: string;

  state$: Observable<Object>;

  changeLang: string;

  isShowPassword = false;

  redirectUrl = '';

  returnUrl = '';

  openSettingAccountDialogVisible = false;

  @ViewChild('passwordWrapper') passwordWrapper!: ElementRef;
  @ViewChild('passwordLabelElement') passwordLabelElement!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
    private userAuthenticationService: UserAuthenticationService,
    private customToastService: CustomToastService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private permissionServiceV2: PermissionServiceV2,
    private renderer: Renderer2
  ) {
    translate.addLangs(['ar', 'en']);
    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    if (!lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(lang);
      this.changeLang = lang === 'ar' ? 'en' : 'ar';
    }
  }

  get email() {
    return this.fGroup.get('email');
  }

  get password() {
    return this.fGroup.get('password');
  }

  ngOnInit() {
    this.createForm();
    // this.openSettingAccountDialog();
    this.state$ = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state),
    );
    this.subscriptionRefs = this.state$.subscribe((data: { error: string }) => {
      this.errMessage = data.error;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.redirectUrl = params.redirect;
      this.returnUrl = params.returnUrl;
      const referralCode = params.ref;
      if (referralCode) {
        this.localStorageService.setItem<string>(
          LocalStorageKey.ReferralCode,
          params.ref,
        );
      }
    });
  }

  ngOnDestroy() {
    this.spinnerService.stopLoading();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const passwordWrapper = this.passwordWrapper.nativeElement;
      const inputWrapper = passwordWrapper.querySelector('.p-inputwrapper.p-password');
      if (inputWrapper && this.passwordLabelElement.nativeElement) {
        this.renderer.appendChild(inputWrapper, this.passwordLabelElement.nativeElement);
      }
    }, 0);
  }

  createForm() {
    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  async login(details: LoginDetails) {
    this.spinnerService.startLoading();
    details.email = details.email.trim();
    try {
      const merchantDetails = await this.userAuthenticationService.login(
        details,
      );
      const isPermissionsV2Enabled = await firstValueFrom(
        this.featureFlagService.isEnabled(FeatureFlagEnum.PermissionsV2),
      );
      if (isPermissionsV2Enabled) {
        await firstValueFrom(this.permissionServiceV2.getUserPermissions());
      }

      this.doRedirection(merchantDetails);
    } catch (err) {
      const errMessageKey =
        this.userAuthenticationService.getErrorMessageKey(err);
      const message = this.translate.instant(errMessageKey);
      this.customToastService.error(message);

      if (
        err.code === 'UserNotConfirmedException' ||
        err.message.includes('User is not confirmed')
      ) {
        this.featureFlagService
          .isEnabled(FeatureFlagEnum.SmsOtp, false)
          .subscribe(async (smsOtpFeatureFlag) => {
            if (smsOtpFeatureFlag) {
              const response =
                await this.userAuthenticationService.getDeliveryMediumForUser(
                  details.email,
                );
              let queryParams = {
                email: details.email,
                type:
                  response.medium === DeliveryMediumType.EMAIL
                    ? UserType.NonGcc
                    : UserType.GccWithoutPh,
                resend: true,
              };
              if (response.medium === DeliveryMediumType.SMS) {
                queryParams = Object.assign(queryParams, {
                  phone: response.text,
                });
              }
              this.router.navigate(['/confirmation'], {
                queryParams,
                state: { password: details.password },
              });
              return;
            }
            this.router.navigate(['/confirmation'], {
              queryParams: { email: details.email },
            });
          });
      }

      if (err.code === 'NotAuthorizedException') {
        await lastValueFrom(this.authService.logAccountDisabled(err));
      }
    } finally {
      this.spinnerService.stopLoading();
    }
  }

  doRedirection(merchantDetails) {
    if (this.redirectUrl) {
      this.userAuthenticationService.getFrillTokenAndRedirect(
        this.redirectUrl,
        merchantDetails.id,
      );
    } else if (merchantDetails.status === 'Pending') {
      this.router.navigateByUrl('/account-setup');
    } else {
      this.router.navigateByUrl(this.returnUrl || '');
    }
  }

  openSettingAccountDialog(): void {
    this.openSettingAccountDialogVisible = true;
  }

  closeSettingAccountDialog(): void {
    this.openSettingAccountDialogVisible = false;
  }
}
