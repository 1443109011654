import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { CustomToastService } from '../../../shared/services/custom-toast.service';

@Component({
  selector: 'rw-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OtpInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OtpInputComponent,
      multi: true,
    },
  ],
})
export class OtpInputComponent
  implements OnDestroy, ControlValueAccessor, Validator, AfterViewInit
{
  constructor(private toastService: CustomToastService) {
    this.otpLength = this.otpLength || 5;
    this.individualValue = [];
    this.keyUp$ = new Subject();
    this.destroy$ = new Subject();
  }

  @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>>;

  @Input() otpLength: number;

  @Output() getOTP = new EventEmitter<string>();

  public onChangeFn: (value: string) => undefined;

  public onTouchFn: () => void;

  private onValidatorChange: () => void;

  private individualValue: string[];

  private keyUp$: Subject<{ event: KeyboardEvent; index: number }>;

  private destroy$: Subject<boolean>;

  @Input() autoFocusInput: boolean;

  value: string;

  disabled: boolean;

  private changeFocus(key: string, index: number): void {
    let position = index;
    if (key === 'Backspace') position = index - 1;
    else position = index + 1;

    if (position > -1 && position < this.otpLength)
      this.inputs.get(position).nativeElement.focus();
  }

  private getCurrentOTP(): string {
    let finalValue = '';
    for (let i = 0; i < this.otpLength; i++) {
      const { value } = this.inputs.get(i).nativeElement;
      finalValue += value;
    }
    return finalValue;
  }

  private getOTPFinalValue() {
    let finalValue = '';
    for (let i = 0; i < this.otpLength; i++) {
      const { value } = this.inputs.get(i).nativeElement;
      finalValue += value;
    }
    this.onChangeFn(finalValue);
    this.getOTP.emit(finalValue);
  }

  private valueChanged(key: string, index: number): void {
    this.getOTPFinalValue();
    this.individualValue[index] = key;
    this.value = this.individualValue.join('');
    this.onChangeFn(this.value);
  }

  testRegex(key: string): boolean {
    const filterRegex = new RegExp(/^([a-zA-Z0-9]|Backspace)$/);
    // for mobile
    if (key?.toLowerCase() === 'unidentified') {
      return true;
    }
    return filterRegex.test(key);
  }

  ngAfterViewInit(): void {
    const firstInputElement = this.inputs.first?.nativeElement;
    if (firstInputElement && this.autoFocusInput) {
      firstInputElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  validate(control: AbstractControl): ValidationErrors {
    const { value } = control;
    if (value && value.length < this.otpLength) return { required: true };
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value) this.individualValue = this.value.split('');
    else this.individualValue = [];
  }

  registerOnChange(fn: (value: string) => undefined): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focusInputAt(index: number): void {
    if (index >= 0 && index < this.otpLength) {
      this.inputs.get(index).nativeElement.focus();
    }
  }

  setInputAt(value: string, index: number): void {
    if (index >= 0 && index < this.otpLength) {
      this.inputs.get(index).nativeElement.value = value;
    }
  }

  clearInputAt(index: number): void {
    if (index >= 0 && index < this.otpLength) {
      this.inputs.get(index).nativeElement.value = '';
    }
  }

  checkOTPValid(value: string, index: number): boolean {
    if (Number.isNaN(+value)) {
      this.clearInputAt(index);
      return false;
    }
    return true;
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    if (
      event.key === 'Backspace' &&
      this.inputs.get(index).nativeElement.value.length > 0
    ) {
      this.clearInputAt(index);
    } else if (event.key === 'Backspace' && index >= 1) {
      this.clearInputAt(index - 1);
      this.focusInputAt(index - 1);
    }
    this.getOTPFinalValue();
  }

  onFocus(index = 0): void {
    if (this.onTouchFn) this.onTouchFn();
    const currentOTPLength = this.getCurrentOTP().length;
    if (index !== currentOTPLength) {
      this.focusInputAt(Math.min(currentOTPLength, this.otpLength - 1));
    }
  }

  otpPopulate(otp: string): void {
    const arr = otp.split('');
    if (arr.length !== this.otpLength) return;
    for (let i = 0; i < this.otpLength; i++) {
      this.setInputAt(arr[i], i);
      this.focusInputAt(i);
    }
    this.getOTPFinalValue();
  }

  showInvalidOtpToast(invalidOtp: string): void {
    this.toastService.error(
      `${
        invalidOtp.length > 0 ? `'${invalidOtp}' is not a valid code. ` : ''
      }The code should consist of 6 digits only.`,
    );
  }

  handlePaste(value: string, index: number): void {
    if (value.length !== this.otpLength) {
      this.clearInputAt(index);
      return;
    }
    this.otpPopulate(value);
  }

  handleInsertText(value: string, index: number): void {
    if (value.length === 6) {
      this.otpPopulate(value);
    } else if (value.length > 1) {
      this.setInputAt(value[0], index);
    } else if (index < 5) {
      this.setInputAt(value, index);
      this.inputs.get(index + 1).nativeElement.focus();
    }
  }

  onChange(event: InputEvent, index: number): void {
    const inputElement = event.target as HTMLInputElement;
    if (this.checkOTPValid(inputElement.value, index)) {
      if (event.inputType === 'insertFromPaste') {
        this.handlePaste(inputElement.value, index);
      } else if (event.inputType === 'insertText') {
        this.handleInsertText(inputElement.value, index);
      }
    }
    this.value = this.getCurrentOTP();
    this.getOTPFinalValue();
  }
}
