import {
  HttpClient,
  HttpContext,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CodeDeliveryDetails } from 'amazon-cognito-identity-js';
import { MerchantUser } from '../auth.models';
import { DeliveryMediumType } from '../../shared/types/auth.types';
import { Customer } from '../../shared/model/order/Customer';

export const API_URL = '/api';
export const NUCLEUS_API_URL = `/api/nucleus`;
// declared HTTPContextToken in this class, because it was causing circular dependency in http-error.interceptor.ts while importing userAuthenticationService
const BYPASS_NORMAL = new HttpContextToken(() => false);

export interface ResendConfirmationCodeResponse {
  resendCount: Record<DeliveryMediumType, number>;
  success: boolean;
  error?: string;
}

export interface Identifier {
  id: number;
  name: string;
}
export interface ForgotPasswordDTO {
  status: string;
  data: { preferredEmail: boolean; resendConfirmationCode: boolean };
  CodeDeliveryDetails: CodeDeliveryDetails;
}

export interface ResendConfirmationDTO {
  CodeDeliveryDetails: CodeDeliveryDetails;
  currentCount: string;
}

export interface UpdateMerchantResponseDTO {
  success: boolean;
  subscriptionId: string;
  subscriptionActive: boolean;
}

export interface UpdateAccountDetailsDTO {
  city: string;
  districtName: string;
  streetName: string;
  postalCode: string;
  vatNumber: string;
  buildingNumber: string;
  additionalNumber?: string;
  countryCode?: string;
  company: string;
}

export interface GetBillingInfoSeparateResponseDTO {
  city: string;
  districtName: string;
  streetName: string;
  postalCode: string;
  vatNumber: string;
  buildingNumber: string;
  additionalNumber?: string;
  countryCode: string;
}

export interface UpdateNavUserPreferenceDTO {
  preference: {
    sideMenu: string;
  };
}

export enum NavUserPreferenceEnum {
  OldNav = 'old',
  NewNav = 'new',
}

@Injectable()
export class MerchantService {
  constructor(private http: HttpClient) {}

  getUsersDropdownList(): Observable<Identifier[]> {
    return this.http.get<Identifier[]>(`${API_URL}/users-dropdownlist`);
  }

  getMerchantByEmail(email): Observable<MerchantUser> {
    return this.http.get<MerchantUser>(`${API_URL}/merchants?email=${email}`);
    // .pipe(
    //   tap((user) => {
    //     localStorage.setItem('user', JSON.stringify(user));
    //     this.userService.setUser(user as AuthUser);
    //   }),
    // );
  }

  getMerchantSignUpStatus(email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${API_URL}/mims-extended/merchants/signup-status?email=${email}`,
    );
  }

  getDeliveryMediumForUser(
    email: string,
  ): Observable<{ medium: DeliveryMediumType; text: string }> {
    return this.http.get<{ medium: DeliveryMediumType; text: string }>(
      `${API_URL}/get-medium?email=${email}`,
    );
  }

  forgotPasswordMerchant(
    email: string,
    preferredEmail = false,
  ): Observable<ForgotPasswordDTO> {
    return this.http.post<ForgotPasswordDTO>(
      `${API_URL}/forgot-password/${email}`,
      { preferredEmail },
      { context: new HttpContext().set(BYPASS_NORMAL, true) },
    );
  }

  forgotPasswordMerchantV1(
    email: string,
  ): Observable<{ status: string; message: string }> {
    return this.http.post<{ status: string; message: string }>(
      `${API_URL}/forgot-password/${email}`,
      {},
    );
  }

  setNewPasswordAndConfirmMerchant(
    email: string,
    code: string,
    password: string,
  ): Observable<ResendConfirmationDTO> {
    return this.http.post<ResendConfirmationDTO>(
      `${API_URL}/confirmation-change-password`,
      {
        email,
        code,
        password,
      },
    );
  }

  /** @deprecated */
  resendConfirmationCode(
    email: string,
  ): Observable<{ status: string; message: string }> {
    return this.http.post<{ status: string; message: string }>(
      `${API_URL}/confirmation/${email}`,
      {},
      { context: new HttpContext().set(BYPASS_NORMAL, true) },
    );
  }

  resendConfirmationCodeV2(
    email: string,
    phone: string,
    deliveryMedium: DeliveryMediumType,
    isMediumChanged: boolean,
  ): Observable<ResendConfirmationCodeResponse> {
    return this.http.post<ResendConfirmationCodeResponse>(
      `${NUCLEUS_API_URL}/merchants/resend-confirmation-code`,
      {
        email,
        deliveryMedium,
        phone,
        isMediumChanged,
      },
      { context: new HttpContext().set(BYPASS_NORMAL, true) },
    );
  }

  confirmSignup(
    email: string,
    code: string,
  ): Observable<{ status: string; message: string }> {
    return this.http.post<{ status: string; message: string }>(
      `${API_URL}/confirm-sign-up`,
      { email, code },
    );
  }

  checkCompanyName(companyName): Observable<{ taken: boolean }> {
    return this.http.get<{ taken: boolean }>(
      `${API_URL}/merchants/${companyName}`,
    );
  }

  getFrillToken(userId): Observable<string> {
    return this.http.get<string>(`${API_URL}/settings/users/${userId}/frill`);
  }

  getCustomerBillingInfo(): Observable<GetBillingInfoSeparateResponseDTO> {
    return this.http.get<GetBillingInfoSeparateResponseDTO>(
      `${API_URL}/mims-extended/customer/get-billing-info`,
    );
  }

  updateCustomerBillingInfo(
    subscriptionId: string,
    payload: UpdateAccountDetailsDTO,
  ): Observable<Customer> {
    return this.http.put<Customer>(
      `${API_URL}/mims-extended/customer/update-billing-info/${subscriptionId}`,
      payload,
    );
  }

  getSideNavUserPreference(): Observable<string> {
    return this.http.get(`${NUCLEUS_API_URL}/v1/user-preferences`, {
      responseType: 'text',
    });
  }

  updateSideNavUserPreference(
    pref: UpdateNavUserPreferenceDTO,
  ): Observable<void> {
    return this.http.patch<void>(
      `${NUCLEUS_API_URL}/v1/user-preferences`,
      pref,
    );
  }
}
